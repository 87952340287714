import { Storage } from "aws-amplify";
import shorthash from "shorthash";

export async function s3Upload(file) {
  try {
    const name = shorthash.unique(file.name);
    const filename = `${Date.now()}-${name}`;
    console.log("adding... " + filename);
    console.log("checking file is here");
    console.log(file);
    const stored = await Storage.put(filename, file, {
      progressCallback(progress) {
        const percentage_progress = Math.round(
          (progress.loaded / progress.total) * 100
        );
        const progress_bar = document.getElementById("progress_bar");
        progress_bar.setAttribute("data-percent", percentage_progress);
        progress_bar.childNodes[0].style.width = percentage_progress + "%";
      },
      contentType: file.type,
    });
    return stored.key;
  } catch (error) {
    console.log(error);
  }
}

export async function s3UploadJson(data) {
  console.log("inside function");
  const file = JSON.stringify(data);
  console.log("about to store this data");
  console.log(data);
  const stored = await Storage.put("trustsList.json", [...data], {
    cacheControl: "no-cache",
  });
  console.log(stored);
  // const name = shorthash.unique(file.name)
  // const filename = `${Date.now()}-${name}`;
  // console.log('adding... ' + filename)
  // const stored = await Storage.put(filename, file, {
  //   progressCallback(progress) {
  //       const percentage_progress = Math.round((progress.loaded/progress.total)*100)
  //       const progress_bar = document.getElementById("progress_bar")
  //       progress_bar.setAttribute("data-percent", percentage_progress)
  //       progress_bar.childNodes[0].style.width = percentage_progress+'%'
  // },
  //   contentType: file.type
  // });
  return stored.key;
}

export async function s3UploadJsonPathway(data, name) {
  try {
    console.log("inside function");
    const stored = await Storage.put(name + ".json", data);
    console.log(stored);
    return stored.key;
  } catch (error) {
    console.log("error with upload");
    console.log(error);
    console.log(name);
    console.log(data);
  }
}

export async function getFile(file) {
  let data = await Storage.get(file, {
    cacheControl: "no-cache",
  });
  return data;
}
