// This is used in testing / client
export default {
  S3: {
    URL: "https://mysunrisemedias3145244-client.s3.eu-west-2.amazonaws.com/",
    EXT: "public/",
  },
};

// This is used in production
// export default {
//   S3: {
//     URL: "https://mysunrisemedias3182935-prod.s3.eu-west-2.amazonaws.com/",
//     EXT: "public/",
//   },
// };

// https://mysunrisemedias3145244-client.s3.eu-west-2.amazonaws.com/public/1666972584066-Zk6Ab2

// Client
// export default {
//   S3: {
//     URL: "https://mysunrisemedias3145244-client.s3.eu-west-2.amazonaws.com/",
//     EXT: "public/",
//   },
// };
